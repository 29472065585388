document.addEventListener("DOMContentLoaded", function () {
  //signup_form
  const resetbutton = document.getElementById('resetform');
  if (resetbutton) resetbutton.addEventListener('click', (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const form = document.getElementById('signup_form');
    if (form) {
      form.classList.remove('thanks');
      form.reset();
      document.getElementById('signup_thanks').style.display = 'none';
      document.getElementById('signup_error').style.display = 'none';
      form.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      document.getElementById('signup_name').focus();
    }
  }, false);
});
