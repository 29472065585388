// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import * as ActiveStorage from "@rails/activestorage"

// import 'bootstrap/dist/js/bootstrap.bundle'
import "../styles/myapp.scss"
import "./mobile-menu"
import "./slider"
import "./selector"
import "./signupreset"
import "./maps"

// Copy all static images under ../images to the output folder
// and reference them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
