
document.addEventListener("DOMContentLoaded", function () {
  function toggleMenu() {
    if (!window.matchMedia('(min-width: 768px)').matches) {
        document.documentElement.classList.toggle('mobile-menu-visible')
    }
  }

  document.querySelectorAll('#open-btn, #close-btn, nav a').forEach(function(a) {
    a.addEventListener("click", toggleMenu);
  });
});
