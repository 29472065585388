import Glide from "@glidejs/glide"

document.addEventListener("DOMContentLoaded", function () {
  const sliders = document.querySelectorAll('.slider__inner');
  const conf = {
    type: 'carousel',
    startAt: 1,
    perView: 3,
    focusAt: 'center',
    animationDuration: 400,
    rewind: true,
    rewindDuration: 400,
    peek: 0,
    gap: 20,
    breakpoints: {
      575: {
        gap: 0,
        peek: -100,
      },
      980: {
        gap: 20,
        peek: 0,
      }
    },
    classes:{
      direction: {
        ltr: 'ltr',
        rtl: 'rtl'
      },
      slider: 'slider',
      carousel: 'carousel',
      swipeable: 'swipeable',
      dragging: 'dragging',
      cloneSlide: 'slide--clone',
      activeNav: 'bullet--active',
      activeSlide: 'slide--active',
      disabledArrow: 'arrow--disabled'
    }
  };
  sliders.forEach((slider, index) => {
    new Glide(slider, conf).mount();
  })
});

