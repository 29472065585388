window.initMap = function(branches, yokocho_icon, kushi_icon) {
  const centerPosition = { lat: 51.509406, lng: -0.1782765 };
  const showAll = branches.length > 1;

  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: showAll ? 13 : 16,
    center: centerPosition,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
  });

  for (const [key, branch] of Object.entries(branches)) {
    let icon;
    let anchor;

    if (branch.key === 'barkushi') {
      icon = kushi_icon;
      anchor = new google.maps.Point(27.5, 0);
    } else if (branch.key === 'heddon') {
      icon = yokocho_icon;
      anchor = new google.maps.Point(27.5, 65);
    } else {
      icon = yokocho_icon;
      anchor = new google.maps.Point(27.5, 32.5);
    }

    if (!showAll) map.setCenter(branch.coords);
    
    const marker = new google.maps.Marker({
      position: branch.coords,
      title: branch.name,
      map: map,
      icon: {
        url: icon,
        anchor: anchor,
      },
    });

    let contentString = '<h2>' + branch.name + '</h2><p>' + branch.address + '</p>';
    if (branch.showLink) {
      contentString += '<p><a href="/location/' + branch.key + '">View</a>';
    } else {
      contentString += '<p>Opening autumn</p>';
    }

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    marker.addListener("click", () => {
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });
  }
}
